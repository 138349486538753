define("discourse/plugins/discourse-netlogo-embed/discourse/initializers/initialize", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Copyright (C) 2024 Kritphong Mongkhonvanit
  //
  // This file is part of discourse-netlogo-embed.
  //
  // discourse-netlogo-embed is free software: you can redistribute it and/or
  // modify it under the terms of the GNU General Public License as published by
  // the Free Software Foundation, either version 3 of the License, or (at your
  // option) any later version.
  //
  // discourse-netlogo-embed is distributed in the hope that it will be useful,
  // but WITHOUT ANY WARRANTY; without even the implied warranty of
  // MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU General
  // Public License for more details.
  //
  // You should have received a copy of the GNU General Public License along with
  // discourse-netlogo-embed. If not, see <https://www.gnu.org/licenses/>. 

  function decoratePost(elem) {
    for (const x of elem.querySelectorAll(".attachment")) {
      console.log(/\.nlogo$/.test(x.href));
      if (/\.nlogo$/.test(x.href)) {
        const params = new URLSearchParams();
        const modelUrl = `${location.protocol}//${location.host}${x.href}`;
        const modelTitle = x.textContent.replace(/\.nlogo$/, "");
        params.set("title", modelTitle);
        params.set("url", modelUrl);
        const iframe = document.createElement("iframe");
        iframe.style = "width: 100%; height: 860px; zoom: 85%;";
        iframe.src = `${location.protocol}//netlogoweb.org/web?${params}`;
        const div = document.createElement("div");
        div.appendChild(iframe);
        x.parentNode.prepend(div);
      }
    }
  }
  function initializeWithApi(api) {
    api.decorateCookedElement(decoratePost);
  }
  var _default = _exports.default = {
    name: "discourse-netlogo-embed",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.netlogo_embed_enabled) {
        (0, _pluginApi.withPluginApi)("1.37.2", initializeWithApi);
      }
    }
  };
});